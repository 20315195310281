<template>
  <div>
    <a-modal width="1600px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" footer @ok="handleOk"
      @cancel="cancel">
      <a-row>
        <a-col :span="8">
          <div style="height:600px;overflow:auto;padding:10px;">
            <div style="border: 1px solid rgb(213, 213, 213);border-radius: 0 0 10px 10px;">
              <h2 style="height:50px;line-height:50px;text-align:center;background: #6BA3F8;color: #fff;">试卷导览</h2>
              <div class="number">
                <div v-for="(item,index) in number" :key="index" :class="{
                  number_type: true,
                  actived: index === checked
                }">
                  <div @click="click(item,index)">{{index+1}} </div>
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="1" />
        <a-col :span="15">
          <div style="height:620px;overflow:auto;padding:10px;">
            <div style="border: 1px solid rgb(213, 213, 213);border-radius: 10px 10px 10px 10px;padding:10px;">
              <span style="border: 1px solid blue;height:10px;width:10px" />
              <span style="margin-left:5px;font-size:18px;">{{examinationName}}</span>
              <div style="text-align: right; font-size:18px;">
                <span style="margin-left:20px;">考试总得分：{{dataInfo.score}}分</span>
                <span style="margin-left:20px;">交卷时间：{{dataInfo.submitTime}}</span>
                <!-- <span style="margin-left:20px;">客观题得分：{{dataInfoQuestion.status2!=1?'0':dataInfoQuestion.score}}分</span> -->
              </div>
              <div style="padding:30px;border: 1px solid rgb(213, 213, 213); height:520px;overflow:auto;padding:10px;">
                <div style="border: 1px solid rgb(213, 213, 213);padding:10px;">
                  <div style="background:#f3f3f3; ">
                    <a-row>
                      <a-col :span="20">
                        <h3 style=" margin-left:20px;line-height:50px;">
                          {{dataInfoQuestion.title}}
                        </h3>
                      </a-col>
                      <a-col :span="1" />
                      <a-col :span="3">
                        <div
                          style="border:1px solid red;width:50px;height:30px;line-height:30px;text-align: center;margin-top: 10px;">
                          <span style=" ">{{status==0?'错误':'正确'}}</span>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                  <a-checkbox-group v-model:value="arrayChoose">
                    <a-row style="margin:20px;" v-for="(item,index) in checkbox" :key="index">
                      <a-col :span="24">
                        <a-checkbox :value="item.label" disabled>
                          选项{{item.label}}
                        </a-checkbox>
                        <span style="margin-left:20px">{{item.content}}</span>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                  <div style="border:1px dashed #f3f3f3"></div>
                  <div style="font-size:18px;margin: 10px 0">试题难度：普通</div>
                  <a-row>
                    <a-col :span="3">
                      <div style="font-size:18px">正确答案：</div>
                    </a-col>
                    <a-col :span="1" />
                    <a-col :span="20">
                      <a-checkbox-group v-model:value="arrayAnswer" style="width:100%">
                        <a-row>
                          <a-col :span="4" v-for="(item,index) in checkbox" :key="index">
                            <a-checkbox :value="item.label" disabled :class="{
                              checkbox: true,
                              activedCheckbox: activedFunction(item.label)
                            }">
                              <span>选项{{item.label}}</span>
                            </a-checkbox>
                          </a-col>
                        </a-row>
                      </a-checkbox-group>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { ExaminationPlanToUserController_id, QuestionRecordControllerFindInfo } from '@/api/api'
export default defineComponent({
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      examinationName:'',
      checkbox: [],
      arrayChoose: [],
      arrayAnswer: [],
      number: [],
      visible: false,
      checked: 0,
      confirmLoading: false,
      userId: '',
      examinationPaperId: '',
      examinationPlanId: '',
      dataInfo: {},
      dataInfoQuestion: {},
      status:"",
    };
  },
  methods: {
    activedFunction(label) {
      return this.arrayAnswer.includes(label)
    },
    onChange(checkedValues) {
      console.log(this.value);
    },
    click(item, index) {
      this.checked = index
      this.handleGetDetails(item)
    },
    cancel() {
      this.visible = false;
    },
    handleOk() {
      this.visible = false;
    },
    handleGetDetails(item) {
      const params = {
        examinationPlanId: this.examinationPlanId,
        examinationPaperId: this.examinationPaperId,
        questionId: item.questionId,
        userId: this.userId,
        source: 2
      }
      QuestionRecordControllerFindInfo(params).then(res => {
        this.status=res.data.status
        this.dataInfoQuestion = res.data.question
        this.checkbox = res.data.question.options
        this.arrayChoose = res.data.arrayChoose
        this.arrayAnswer = res.data.question.arrayAnswer
      })
    },
    showModal(examinationName,examinationPlanUserId, index = 0) {
      this.visible = true;
      this.examinationName=examinationName
      this.checked = index
      ExaminationPlanToUserController_id(examinationPlanUserId).then(res => {
        this.number = res.data.examinationPaperToQuestions
        this.userId = res.data.userId
        this.examinationPaperId = res.data.examinationPaperId
        this.examinationPlanId = res.data.examinationPlanId
        this.dataInfo = res.data
        this.handleGetDetails(this.number[0])
      })
    },
  },
});
</script >
<style lang="less" scoped>
.number {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
}

.number_type {
  margin: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #f3f3f3;
  color: rgb(0, 0, 0);
  border-radius: 10% 10%;
  cursor: pointer;
}

.actived {
  background: #6ba3f8;
  color: #fff;
}

.checkbox {
  margin: 10px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #f3f3f3;
}

.activedCheckbox {
  background: #6ba3f8;

  span {
    color: #fff;
  }
}
</style>
    