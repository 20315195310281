
import { defineComponent, PropType, reactive, toRefs, ref, h } from "vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import popupWindow from "./popupNew.vue";
import formSearch from "@/components/page-search/form";
import { ExaminationPlanToUserControllerPage } from '@/api/api'
import { ExaminationPlanToUserController } from '@/api/downloadApi'
import {blob} from '@/utils/blob'
import { message } from 'ant-design-vue';

const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "考试名称 ",
    dataIndex: "examinationName",
  },
  {
    title: "考生姓名",
    dataIndex: "name",
  },
  {
    title: "考试时长",
    dataIndex: "duration",
  },
  {
    title: "考生分数",
    dataIndex: "score",
  },
  {
    title: "是否合格",
    dataIndex: "status",
    customRender: (row: TransformCellTextProps) =>
      row.text === 0 ? "未参加考试" : (row.text === 1 ? "不合格" : "合格"),
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    formSearch,
    popupWindow,
  },
  setup(props: any) {
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 6 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        //试题描述：
        {
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "考试名称：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "examinationName", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        {
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "考生姓名：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "name", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        //试题类别：
        {
          rowProps: {},
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "是否合格",
          },
          comConfig: {
            tag: "select",
            key: "status",
            valueKey: "id",
            labelKey: "name",
            getDataApi: () => {
              return new Promise((reslove) => {
                setTimeout(() => {
                  reslove([
                    {
                      name: "合格",
                      id: 2,
                    },
                    {
                      name: "不合格",
                      id: 1,
                    },
                    {
                      name: "未参加考试",
                      id: 0,
                    },
                  ]);
                }, 500);
              });
            },
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref()

    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          ...formSearch.value.getQuery(),
          'startSubmitTime': updatedTime.value[0],
          'endSubmitTime': updatedTime.value[1]
        }
        ExaminationPlanToUserControllerPage(params_api.value).then(res => {
          reslove(res)
        })
      });
    };
    function handleDownload() {
      ExaminationPlanToUserController({ ...params_api.value }).then(res => {
        blob(res)
      })
    }
    function downloadSelectApi() {
      if(data.selectedRowKeys.length>0){
        console.log(data.selectedRowKeys);
        message.warning('当前数据为统计数据，无法单独导出，请点击全部导出');
      }else{
        message.warning('请先选择需要下载的内容');
      }
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };

    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const updatedTime = ref([]);
    const handleSearch = (formState: any) => {
      // formSearch.value.getQuery();
      // console.log({ ...formSearch.value.getQuery() });
      // console.log("插槽", { ...updatedTime.value });
      table.value.refresh();
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "查看答卷";
      popupWindow.value.showModal(row.examinationName, row.examinationPlanUserId);
    };
    const reset = () => {
      updatedTime.value = []
    };
    return {
      downloadSelectApi,
      handleDownload,
      reset,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleSearch,
      updatedTime,
    };
  },
});
